import React from 'react';
import { Component } from 'react';
import { Alert, Button, Container, Row, Col } from 'reactstrap';
import * as qs from "qs";
import { PushInitConfig, PushRegistration } from '@aerogear/push';
import { Media } from 'reactstrap';
import './App.css';

enum STATE {
    REGISTERED,
    NOT_REGISTERED,
    ERROR,
    URLERROR
}

interface Notification {
    priority: string;
    text: string;
    date: Date;
}

export interface C2 {
    userId: string;
    customerId: string;
}
interface NotificationsState {
    registrationState: STATE;
    error?: string;
    notifications?: Notification[];
    config?: PushInitConfig;
    reconfig?: boolean;
    config2?: C2
}

class Notifications extends Component<{}, NotificationsState> {
    constructor(props: {}) {
        super(props);
        const query = qs.parse(window.location.href.split("?")[1])
        if (!query.token || !query.customerid || !query.userid) {
            this.state = { registrationState: STATE.URLERROR }
        } else {
            const old = localStorage.UPS_REGISTRATION_DATA ? JSON.parse(localStorage.UPS_REGISTRATION_DATA) : null

            this.state = {
                registrationState: old && old.alias === query.userid ? STATE.REGISTERED : STATE.NOT_REGISTERED,
                reconfig: true,
                config: {
                    url: "https://push-backend.iot.lemonsoft.com.tr",
                    webpush: {
                        appServerKey: "BF0iZomSeFfAdJ99EcM2NUdmn02n8c1ywaUZuh-OINc_c6cPX01ow0FT8VZc28_FTOOLrvcIetnY9AUbNFIvcOg",
                        variantID: "2b55d88d-936b-4d98-9c00-da910ab60206",
                        variantSecret: "ae558cab-a161-4998-946f-3f6809426a5f"
                    }
                },
                config2: {
                    userId: query.userid,
                    customerId: query.customerid
                }
            };
        }

        /**
         * Registers the callback to be called whenever a message is received
         */
        PushRegistration.onMessageReceived(notification => {
            const notifications = this.state.notifications || ([] as Notification[]);

            const obj = JSON.parse(notification);
            notifications.push({
                priority: obj.priority,
                text: obj.alert,
                date: new Date(),
            });

            this.setState({ notifications });
        });
    }

    /**
     * Resister to the UPS
     */
    private register = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        new PushRegistration(this.state.config!)
            .register({ serviceWorker: 'aerogear-sw.js', alias: this.state.config2!.userId, categories: ["c" + this.state.config2!.customerId, isMobile ? "mobile" : "desktop"] })
            .then(() => {
                this.setState({ registrationState: STATE.REGISTERED });
            })
            .catch(error => {
                this.setState({ registrationState: STATE.ERROR, error: error.message });
            });
    };

    /**
     * Unregister from UPS
     */
    private unregister = () => {
        new PushRegistration(this.state.config!)
            .unregister()
            .then(() => {
                this.setState({
                    registrationState: STATE.NOT_REGISTERED,
                    notifications: [] as Notification[],
                });
            })
            .catch(error => {
                this.setState({ registrationState: STATE.ERROR, error: error.message });
            });
    };

    private forceUn = async () => {
        if ("serviceWorker" in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (const registration of registrations) {
                await registration.unregister();
            }
        }
        this.setState({
            registrationState: STATE.NOT_REGISTERED,
            notifications: [] as Notification[],
        });
    };

    render(): React.ReactElement {

        return <>
            {this.state.config2?.customerId === "teker" && <Row style={{ marginTop: "1rem" }}>
                <Col>
                    <img src="/teker-l.png" alt="teker" style={{ width: "100%", maxWidth: "400px" }} />
                </Col>
            </Row>}
            <Row style={{ marginTop: "1rem" }}></Row>
            <Row>
                <Col>
                    {this.state.registrationState === STATE.ERROR && <Alert color="danger">
                        {this.state.error}
                    </Alert>}
                    {this.state.registrationState === STATE.ERROR && <Button onClick={this.forceUn}>Bildirim kaydını iptal et, yeniden dene</Button>}
                    {this.state.registrationState === STATE.URLERROR && <Alert color="danger">
                        Lütfen tekrar giriş yapınız
                    </Alert>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {this.state.registrationState === STATE.REGISTERED && <Alert color="primary">
                        Bu cihaz bildirimler için kayıtlı
                    </Alert>}
                    {this.state.registrationState === STATE.NOT_REGISTERED && <Alert color="primary">
                        Bu cihazda bildirimle kayıt olamak için aşağıdaki butona basın ve bildirim izni verin
                    </Alert>}
                </Col>
            </Row>
            <Row>
                <Col>
                    {this.state.registrationState === STATE.REGISTERED && (
                        <Button onClick={this.unregister}>Bildirim kaydını iptal et</Button>
                    )}
                    {this.state.registrationState === STATE.NOT_REGISTERED && (
                        <Button onClick={this.register}>Bildirimlere kayıt ol</Button>
                    )}
                </Col>
            </Row>
        </>
    }
}
export default Notifications