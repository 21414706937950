import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as qs from "qs";

import Notifications from './Notifications';
import { Container } from 'reactstrap';
import Message from './Message';

/* tslint:disable */
const App = () => {
    const query = qs.parse(window.location.href.split("?")[1])

    return (
        <Container>
            {query.message ? <Message /> : <Notifications />}
        </Container>
    );
};

export default App;
