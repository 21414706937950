import React from 'react';
import { Component } from 'react';
import { Alert, Button, Container, Row, Col, Card, CardText } from 'reactstrap';

import * as qs from "qs";

const App = () => {
    const query = qs.parse(window.location.href.split("?")[1])

    return (
        <>
            {query.app === "teker" && <Row style={{ marginTop: "1rem" }}>
                <Col>
                    <img src="/teker-l.png" alt="teker" style={{ width: "100%", maxWidth: "400px" }} />
                </Col>
            </Row>}
            <Row style={{ marginTop: "1rem" }}></Row>
            <Row>
                <Col>
                    <Card body className="text-center">
                        <CardText>{query.message}</CardText>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default App;
